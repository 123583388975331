/* eslint-disable */
//@ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Space,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import { useMutation, useQuery } from "react-query";
import {
  getAccounts,
  getBudgetsLastReference,
  postBudget,
  updateBudget,
} from "api/budgets";
import AttachmentField from "./AttachmentField";
import { AxiosError } from "axios";
import BudgetDimensions from "./BudgetDimensions";
import { Account, Budget, TreeNode } from "types/budget";
import { transformData } from "helpers/budgets";



const AccountBudgeting = ({
  type = "new",
  budget = {},
  id = null,
  hasValues = false,
}) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { t } = useTranslation();
  const currentYear = dayjs();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const disabledDate = (current: Dayjs | null): boolean => {
    return current ? current.year() < new Date().getFullYear() : false;
  };

  const alphanumericPattern = /^[a-zA-Z0-9-_]*\d$/;

  const createBudget = useMutation({
    mutationFn: (data) => postBudget(data, id),
    onSuccess() {
      window.location.href = "/tenant/budgets";
    },
    onError: (error: AxiosError<any>) => {
      if (error.response.data.code || error.response.data.errors) {
        message.error(
          t("budgets.accounts_budgeting.fields.reference.validation3")
        );
      }
    },
  });

  const editBudget = useMutation({
    mutationFn: (data) => updateBudget(data, id),
    onSuccess() {
      window.location.href = "/tenant/budgets";
    },
    onError: (error: AxiosError<any>) => {
      if (error.response.data.code || error.response.data.errors) {
        message.error(
          t("budgets.accounts_budgeting.fields.reference.validation3")
        );
      }
    },
  });

  const lastReference = useQuery({
    queryKey: "budgetsLastReference",
    queryFn: () => getBudgetsLastReference(),
  }).data?.next_code;

  const accounts = useQuery({
    queryKey: "accounts",
    queryFn: () => getAccounts(),
  }).data?.accounts;

  const transformedData = transformData<Account>(accounts, "code_name");
  const filterTreeNode = (inputValue: string, treeNode: TreeNode) => {
    return (
      treeNode.title.toLowerCase().includes(inputValue.toLowerCase()) ||
      treeNode.value == inputValue
    );
  };

  const handleSubmit = (formData: FormData) => {
    if (type === "new") {
      createBudget.mutate(formData as any);
    } else {
      editBudget.mutate(formData as any);
    }
  };
  
  const onFinish = (values: Budget) => {
    const formData = new FormData();
    if (type == "new") {
    formData.append("budget[kind]", "accounts_budget");
    formData.append("budget[code]", values.code);
    values.description &&
      formData.append("budget[description]", values.description);
    formData.append("budget[name_ar]", values.name_ar);
    formData.append("budget[name_en]", values.name_en);
    !hasValues &&
      formData.append(
        "budget[fiscal_year]",
        values.fiscal_year.add(0, "month").toISOString()
      );
    !hasValues &&
      values.accounts_ids.forEach((el, i) => {
        formData.append(`budget[account_ids][]`, el);
      });

    const attachment = values.attachment?.map((el) => {
      return el.originFileObj;
    });

    attachment?.forEach((file) => {
      formData.append(`budget[attachments][]`, file);
    });

    values.dimensions?.forEach((el) => {
      formData.append(`budget[dimension_ids][]`, el.selectedDimension.value);
    });
    handleSubmit(formData);
  } else {
    let hasData = false;
    const updatedFormData = new FormData();
    for (const key in values) {
      if (
        values.hasOwnProperty(key) &&
        JSON.stringify(values[key]) !== JSON.stringify(initialValues[key])
      ) {
        if (key === "fiscal_year") {
          const fiscalYearValue = values[key].add(0, "month").toISOString();
          updatedFormData.append(`budget[${key}]`, fiscalYearValue);
    } else if (key === "accounts_ids") {
          values[key].forEach((el) => {
            updatedFormData.append(`budget[account_ids][]`, el);            
          });
        } else if (key === "dimensions") {
          values[key].forEach((dimension) => {
            const dimensionId = dimension.selectedDimension?.value; 
            updatedFormData.append(`budget[dimension_ids][]`, dimensionId);
          });
        } else if (key === "attachment") {
          const attachment = values.attachment?.map((el) => el.originFileObj);
          attachment?.forEach((file) => {
            updatedFormData.append("budget[attachments][]", file);
          });
        } else {
          updatedFormData.append(`budget[${key}]`, values[key]);
        }
      }
    }    
    updatedFormData.forEach((value, key) => {
      hasData = true;
    });   
    if (hasData) {
        handleSubmit(updatedFormData as any);   
    } 
  }
  };

  useEffect(() => {
    if (lastReference) {
      const incomingData = type == "edit" ? budget : { code: lastReference };
      form.setFieldsValue(incomingData);
      setInitialValues(incomingData);
    }
  }, [lastReference, form]);

  return (
    <Form
      name="accountBudgeting"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600, fontSize: "12px", fontWeight: "normal" }}
      onFinish={onFinish}
      initialValues={{
        fiscal_year: currentYear,
      }}
      autoComplete="off"
      onValuesChange={() => setIsFormChanged(true)}
    >
      <Form.Item
        label={t("budgets.accounts_budgeting.fields.reference.name")}
        name="code"
        rules={[
          {
            required: true,
            message: t(
              "budgets.accounts_budgeting.fields.reference.validation1"
            ),
          },
          {
            max: 25,
            message: t(
              "budgets.accounts_budgeting.fields.reference.validation4"
            ),
          },
          {
            pattern: alphanumericPattern,
            message: t(
              "budgets.accounts_budgeting.fields.reference.validation2"
            ),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("budgets.accounts_budgeting.fields.description.name")}
        name="description"
        rules={[
          {
            max: 100,
            message: t(
              "budgets.accounts_budgeting.fields.description.validation"
            ),
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item
        label={t("budgets.accounts_budgeting.fields.arabic_name.name")}
        name="name_ar"
        rules={[
          {
            required: true,
            message: t(
              "budgets.accounts_budgeting.fields.arabic_name.validation"
            ),
          },
          {
            max: 25,
            message: t(
              "budgets.accounts_budgeting.fields.arabic_name.validation1"
            ),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("budgets.accounts_budgeting.fields.english_name.name")}
        name="name_en"
        rules={[
          {
            required: true,
            message: t(
              "budgets.accounts_budgeting.fields.english_name.validation"
            ),
          },
          {
            max: 25,
            message: t(
              "budgets.accounts_budgeting.fields.english_name.validation1"
            ),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("budgets.accounts_budgeting.fields.fiscal_year.name")}
        name="fiscal_year"
        rules={[
          {
            required: true,
            message: t(
              "budgets.accounts_budgeting.fields.fiscal_year.validation"
            ),
          },
        ]}
      >
        <DatePicker
          disabled={hasValues}
          inputReadOnly
          picker="year"
          disabledDate={disabledDate}
        />
      </Form.Item>
      <Form.Item
        label={t("budgets.accounts_budgeting.fields.accounts.name")}
        name="accounts_ids"
        rules={[
          {
            required: true,
            message: t("budgets.accounts_budgeting.fields.accounts.validation"),
          },
        ]}
      >
        <TreeSelect
          disabled={hasValues}
          treeData={transformedData}
          treeCheckable
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          style={{ width: "100%" }}
          filterTreeNode={filterTreeNode}
        />
      </Form.Item>
      <AttachmentField />
      <BudgetDimensions type={type} budget={budget} hasValues={hasValues} />
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Space>
          <Button type="primary" 
          htmlType="submit"
          disabled={!isFormChanged}
          >
            {type == "edit"
              ? t("budgets.edit.update")
              : t("forms.buttons.save")}
          </Button>
          <Button htmlType="button" onClick={() => window.go_back()}>
            {t("forms.buttons.cancel")}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AccountBudgeting;
